/**
 * Created by hoa on 03/05/2017.
 */

define(['simpleSlider'], function(simpleSlider) {
  

  var fourItemWindowProductSlider = function() {
    var self = this;

    // four item product slider configs
    var _config = {
      links: '.fourItemWindowProductSlider_navBullet .fourItemWindowProductSlider_navLink',
      wrapper: '.fourItemWindowProductSlider_slideWrap',
      prev: '.fourItemWindowProductSlider_navButton-prev',
      next: '.fourItemWindowProductSlider_navButton-next',
      slide: '.fourItemWindowProductSlider_slideItem',
      slideCaption: '.simpleSlider_slide-caption',
      slideNav: '.simpleSlider_slide-nav',
      loop: true
    };

    // initiate simple slider
    var _init = function() {
      self.promoSlider = new simpleSlider('.fourItemWindowProductSlider', _config);
    };

    self.config = _config;
    self.init = _init;
  };

  return fourItemWindowProductSlider;

});
